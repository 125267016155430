import { motion } from 'framer-motion';
import EventItem from '../EventItem';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
class ShowsView extends Component {
  onClickEvent = (eventId) => {
    if (eventId) {
      this.props.history.push(`/event-details/${eventId}`);
    }
  };
  render() {
    const { data } = this.props;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="flex w-full flex-col lg:pb-24"
      >
        {data.map((item, i) => (
          <EventItem
            key={item.text.name}
            index={i}
            category="shows"
            label={item.text.name}
            onClick={() =>
              this.onClickEvent(
                `${item.text.name.replace(/\s+/g, '-')}-tickets-${item.city.text.name.replace(/\s+/g, '-')}-${
                  item.date.date
                }/${item.id}`
              )
            }
          />
        ))}
      </motion.div>
    );
  }
}

export default withRouter(connect(null, {})(ShowsView));
