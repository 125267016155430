import { Component } from 'react';

export default class HiddenFees extends Component {
  static defaultProps = {
    backdrop: true,
  };
  render() {
    const { backdrop } = this.props;
    return (
      <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        {backdrop && (
          <>
            <rect width="55" height="55" rx="6" fill="url(#paint0_linear_0_1)" />
            <rect x="0.5" y="0.5" width="54" height="54" rx="5.5" stroke="black" strokeOpacity="0.29" />
          </>
        )}
        <path
          d="M33.3449 27.6806C33.3449 30.7789 30.7233 33.2905 27.4894 33.2905C24.2555 33.2905 21.6339 30.7789 21.6339 27.6806C21.6339 24.5823 24.2555 22.0707 27.4894 22.0707C30.7233 22.0707 33.3449 24.5824 33.3449 27.6806ZM27.5 16C24.1517 16.0149 20.681 16.83 17.3956 18.3906C14.9562 19.5971 12.5789 21.2992 10.5153 23.4002C9.50176 24.4727 8.20901 26.0256 8 27.6825C8.0247 29.1177 9.56422 30.8892 10.5153 31.9647C12.4505 33.9832 14.7659 35.6378 17.3956 36.9756C20.4594 38.4625 23.8506 39.3186 27.5 39.3662C30.8515 39.3511 34.3214 38.5266 37.6031 36.9756C40.0425 35.7691 42.421 34.0657 44.4847 31.9647C45.4982 30.8923 46.791 29.3393 47 27.6825C46.9753 26.2472 45.4358 24.4756 44.4847 23.4001C42.5495 21.3817 40.2329 19.7283 37.6031 18.3905C34.5409 16.9048 31.1414 16.0546 27.5 16ZM27.4975 18.9006C32.5714 18.9006 36.6845 22.8329 36.6845 27.6837C36.6845 32.5346 32.5714 36.4668 27.4975 36.4668C22.4237 36.4668 18.3106 32.5345 18.3106 27.6837C18.3106 22.8329 22.4237 18.9006 27.4975 18.9006Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear_0_1" x1="0" y1="0" x2="55" y2="55" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8244DC" />
            <stop offset="1" stopColor="#462476" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}
