import { motion } from 'framer-motion';
import EventItem from '../EventItem';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ArtistView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artistItems: [],
    };
  }

  onClickArtist = (performerName) => {
    if (performerName) {
      this.props.history.push(`/events-results/performer-tickets/${performerName.replace(/\s+/g, '-')}`);
    }
  };

  render() {
    const { data } = this.props;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="flex w-full flex-col lg:pb-24"
      >
        {data.map((item, i) => (
          <EventItem
            key={item.text.name}
            index={i}
            category="artists"
            label={item.text.name}
            onClick={() => this.onClickArtist(item.text.name)}
          />
        ))}
      </motion.div>
    );
  }
}

export default withRouter(connect(null, {})(ArtistView));
