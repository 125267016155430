import Fire from './Fire';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './footer.css';
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisclaimer: false,
    };
    this.toggleDisclaimer = this.toggleDisclaimer.bind(this);
  }

  toggleDisclaimer() {
    this.setState((prevState) => ({
      showDisclaimer: !prevState.showDisclaimer,
    }));
  }
  onClickSportsName = (sportsName) => {
    if (sportsName) {
      this.props.history.push(`/sport-search/${sportsName}`);
    }
  };
  render() {
    const { showDisclaimer } = this.state;
    const footerLinks = [
      {
        name: 'Terms of Service',
        href: '/policies',
        popular: true,
      },
      {
        name: 'Privacy Policy',
        href: '/policies',
        popular: false,
      },
      {
        name: 'Cookie Policy',
        href: '/policies',
        popular: false,
      },
    ];
    return (
      <footer className="flex w-full flex-col gap-6 bg-main py-12">
        <div className="container-tw flex flex-col lg:flex-row items-start justify-between gap-4 ">
          <div className="w-full lg:w-1/2 flex flex-col items-start justify-center gap-4">
            <h1 className="text-[36px] font-medium text-white lg:text-[40px]">
              Do you have
              <br />
              any questions?
            </h1>
            <article className="flex w-full flex-col items-start ">
              <h3 className="text-[14px] font-normal text-[#E6E6E6] lg:text-base">
                Feel free to send us your questions
              </h3>
            </article>
            <a
              href="https://accounts.sourcedtickets.com/?register=false"
              target="_blank"
              className="w-full rounded-md text-center bg-highlight-2 text-white px-6 py-2 text-[12px] font-semibold lg:w-fit"
              style={{ textDecoration: 'none' }}
            >
              Join Now
            </a>
            <h3 className="flex flex-row items-center justify-center gap-2 text-[14px] font-normal text-[#E6E6E6] lg:text-base">
              <span className="text-[24px] font-bold text-highlight-2">/</span>
              Thank you for choosing Sourced Tickets
            </h3>
          </div>
          <div className="w-full lg:w-1/2 flex flex-wrap lg:flex-nowrap flex-row items-start justify-between gap-10 text-white">
            <div className="footer-links-new">
              <h3> Browse </h3>
              <ul>
                <li onClick={() => this.onClickSportsName('NFL Football'.replace(/\s+/g, '-'))}>NFL Football</li>
                <li onClick={() => this.onClickSportsName('NBA Basketball'.replace(/\s+/g, '-'))}>NBA Basketball</li>
                <li onClick={() => this.onClickSportsName('NHL Hockey'.replace(/\s+/g, '-'))}>NHL Hockey </li>
                <li onClick={() => this.onClickSportsName('MLB Baseball'.replace(/\s+/g, '-'))}> MLB Baseball</li>
                <li onClick={() => this.onClickSportsName('NCAA Football'.replace(/\s+/g, '-'))}>NCAA Football</li>
              </ul>
            </div>
            <div className="footer-links-new">
              <h3> Resources </h3>
              <ul>
                <li>
                  <a href="https://accounts.sourcedtickets.com/?register=false" target={'_blank'}>
                    Login
                  </a>
                </li>
                <li>
                  <a href="https://accounts.sourcedtickets.com/?register=true" target={'_blank'}>
                    Signup{' '}
                  </a>
                </li>
                <li>
                  <a href="https://sourcedtickets.com/policies" target={'_blank'}>
                    Policies{' '}
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-links-new">
              <h3> Connect </h3>
              <ul>
                <li>
                  <a href="https://www.instagram.com/sourcedtickets?igsh=MTJ6b2E3Mms4eTVycQ==" target={'_blank'}>
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@sourcedtickets.com?_t=8mU8DTXjKR8&_r=1" target={'_blank'}>
                    TikTok
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/share/XGkKqVYHCdGxjN4y/?mibextid=LQQJ4d" target={'_blank'}>
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Disclaimer */}
        <div className="container-tw flex w-full flex-col items-start justify-start">
          <hr className="lg:mb-10 w-full border-t-[1px] bg-footer border-t-[#E6E6E6]" />

          <article className="flex flex-row items-center justify-center gap-2">
            <h3 className="text-[14px] font-normal text-white/50">Disclaimer</h3>
            <button
              className="text-white/50 transition-all duration-300 hover:text-highlight-2"
              onClick={this.toggleDisclaimer}
            >
              {showDisclaimer ? '-' : '+'}
            </button>
          </article>
          <AnimatePresence>
            {showDisclaimer && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="overflow-hidden"
              >
                <p className="text-[14px] font-normal text-white/50">
                  This website is not affiliated with any event organizers or venues. All ticket sales are final and
                  non-refundable. Sourced Tickets is not responsible for any issues arising from the purchase or use of
                  tickets.
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Links and Contact */}
        <div className="container-tw flex flex-col items-start justify-between gap-10">
          {/* Contact */}
          <div className="flex w-full flex-row items-center justify-end">
            {/* Copyright */}
            <article className="flex flex-col items-end justify-end gap-2">
              <h3 className="text-right text-[10px] font-medium text-white/50">SOURCED TICKETS</h3>
              <p className="text-right text-[10px] font-normal text-white/50">
                &copy; {new Date().getFullYear()} Sourced Tickets. <br /> All rights reserved.
              </p>
            </article>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(connect(null, null)(Footer));
