import { Component } from 'react';

export default class Inventory extends Component {
  static defaultProps = {
    backdrop: true,
  };
  render() {
    const { backdrop } = this.props;
    return (
      <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        {backdrop && (
          <>
            <rect width="55" height="55" rx="6" fill="url(#paint0_linear_0_1)" />
            <rect x="0.5" y="0.5" width="54" height="54" rx="5.5" stroke="black" strokeOpacity="0.29" />
          </>
        )}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0945 14.49C11.1965 14.1834 11.4161 13.9299 11.7049 13.7851C11.9938 13.6404 12.3284 13.6163 12.635 13.7181L13.1306 13.8839C14.1479 14.2235 15.0075 14.5095 15.6851 14.8247C16.4034 15.1595 17.0274 15.5722 17.5002 16.2304C17.9731 16.8852 18.1697 17.6084 18.2591 18.3965C18.3025 18.7995 18.3279 19.2507 18.3355 19.7501H35.7327C39.0721 19.7501 40.741 19.7501 41.4641 20.8454C42.1856 21.9406 41.5291 23.4762 40.2129 26.5459L39.5174 28.1709C38.9031 29.6041 38.596 30.3191 37.985 30.7221C37.374 31.1251 36.5956 31.1251 35.0356 31.1251H18.5922C18.7629 32.0026 19.0326 32.5161 19.4145 32.898C19.8646 33.3481 20.4967 33.6406 21.6895 33.8015C22.9164 33.9656 24.543 33.9689 26.8749 33.9689H38.2499C38.5731 33.9689 38.8831 34.0973 39.1117 34.3258C39.3402 34.5544 39.4686 34.8644 39.4686 35.1876C39.4686 35.5108 39.3402 35.8208 39.1117 36.0494C38.8831 36.278 38.5731 36.4064 38.2499 36.4064H26.7855C24.5641 36.4064 22.7717 36.4064 21.3629 36.2162C19.9004 36.0212 18.6686 35.5987 17.6904 34.6221C16.7137 33.6439 16.2929 32.4121 16.0962 30.9496C15.9061 29.5407 15.9061 27.7484 15.9061 25.527V21.185C15.9061 20.0264 15.9061 19.2594 15.8379 18.6727C15.7729 18.117 15.664 17.8489 15.5226 17.6555C15.3829 17.4605 15.1635 17.2704 14.6565 17.0347C14.1202 16.7861 13.3939 16.5407 12.2954 16.1735L11.8647 16.0305C11.5585 15.9281 11.3053 15.7084 11.1609 15.4196C11.0165 15.1308 10.9926 14.7964 11.0945 14.49ZM21.9999 23.4064C21.6766 23.4064 21.3666 23.5348 21.1381 23.7633C20.9095 23.9919 20.7811 24.3019 20.7811 24.6251C20.7811 24.9483 20.9095 25.2583 21.1381 25.4869C21.3666 25.7155 21.6766 25.8439 21.9999 25.8439H26.8749C27.1981 25.8439 27.5081 25.7155 27.7367 25.4869C27.9652 25.2583 28.0936 24.9483 28.0936 24.6251C28.0936 24.3019 27.9652 23.9919 27.7367 23.7633C27.5081 23.5348 27.1981 23.4064 26.8749 23.4064H21.9999Z"
          fill="white"
        />
        <path
          d="M21.1875 39.25C21.834 39.25 22.454 39.5068 22.9111 39.9639C23.3682 40.421 23.625 41.041 23.625 41.6875C23.625 42.334 23.3682 42.954 22.9111 43.4111C22.454 43.8682 21.834 44.125 21.1875 44.125C20.541 44.125 19.921 43.8682 19.4639 43.4111C19.0068 42.954 18.75 42.334 18.75 41.6875C18.75 41.041 19.0068 40.421 19.4639 39.9639C19.921 39.5068 20.541 39.25 21.1875 39.25ZM35.8125 39.25C36.459 39.25 37.079 39.5068 37.5361 39.9639C37.9932 40.421 38.25 41.041 38.25 41.6875C38.25 42.334 37.9932 42.954 37.5361 43.4111C37.079 43.8682 36.459 44.125 35.8125 44.125C35.166 44.125 34.546 43.8682 34.0889 43.4111C33.6318 42.954 33.375 42.334 33.375 41.6875C33.375 41.041 33.6318 40.421 34.0889 39.9639C34.546 39.5068 35.166 39.25 35.8125 39.25Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear_0_1" x1="0" y1="0" x2="55" y2="55" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8244DC" />
            <stop offset="1" stopColor="#462476" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}
