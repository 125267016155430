import { Component } from 'react';

export default class Secure extends Component {
  render() {
    return (
      <svg width="45" height="45" viewBox="0 0 46 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M42.5556 23H3.44444C2.09441 23 1 24.0944 1 25.4444V47.4444C1 48.7945 2.09441 49.8889 3.44444 49.8889H42.5556C43.9056 49.8889 45 48.7945 45 47.4444V25.4444C45 24.0944 43.9056 23 42.5556 23Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M10.7778 23V13.2222C10.7778 6.47189 16.2497 1 23.0001 1C29.7504 1 35.2223 6.47189 35.2223 13.2222V23"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M23 32.7778V40.1112" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
}
