import heroImage from '../../assets/images/hero/hero-img.png';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class HeroSection extends Component {
  render() {
    return (
      <section
        id="hero"
        className="hero-section flex min-h-[75dvh] w-screen max-w-[100dvw] flex-col items-center justify-center overflow-clip px-4 pt-[80px] lg:p-24"
      >
        <div className="container-tw relative flex h-full w-full flex-col gap-6 p-0 lg:pb-10 xl:gap-10">
          <article className="lg:max-w-[65%]">
            <h1 className="text-center text-[32px] font-black leading-tight text-white lg:text-left lg:text-[64px]">
              Experience <span className="text-highlight-2"> more.</span>
            </h1>
            <h1 className="text-center text-[32px] font-black leading-tight text-white lg:text-left lg:text-[64px]">
              Spend <span className="text-highlight-2"> less</span> with Sourced Tickets.
            </h1>
          </article>
          <article className="text-center  lg:max-w-[55%] lg:text-left">
            <p className="leading-loose text-lg text-white/60 lg:leading-normal">
              Find tickets for the hottest concerts and live events. Browse upcoming shows, select your seats, and
              secure your spot at unforgettable performances. Stay tuned—more events are coming soon!
            </p>
          </article>
          <div className="flex w-full items-center justify-center gap-4 text-white lg:w-fit">
            <a
              href="https://accounts.sourcedtickets.com/?register=false"
              target="_blank"
              className="rounded-md border border-white bg-main px-4 py-2 z-20"
              style={{ textDecoration: 'none' }}
            >
              Login
            </a>
            <a
              href="https://accounts.sourcedtickets.com/?register=true"
              target="_blank"
              className="rounded-md bg-highlight-2 px-4 py-2 z-20"
              style={{ textDecoration: 'none' }}
            >
              Join Now
            </a>
          </div>
          <div className="hero-blur" aria-hidden />
          <div className="hero-image">
            <img src={heroImage} alt="Hero Image" loading="eager" />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.isMobile,
});
export default withRouter(connect(mapStateToProps, {})(HeroSection));
