import { Component } from 'react';

export default class Trophy extends Component {
  static defaultProps = {
    backdrop: true,
  };
  render() {
    const { backdrop } = this.props;
    return (
      <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        {backdrop && (
          <>
            <rect width="55" height="55" rx="6" fill="url(#paint0_linear_0_1)" />
            <rect x="0.5" y="0.5" width="54" height="54" rx="5.5" stroke="black" strokeOpacity="0.29" />
          </>
        )}
        <path d="M27.5 36V40.875" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        <path
          d="M33.1875 45.75H21.8125L22.365 42.994C22.4386 42.6258 22.6375 42.2944 22.9278 42.0562C23.2181 41.818 23.582 41.6877 23.9575 41.6875H31.0425C31.418 41.6877 31.7819 41.818 32.0722 42.0562C32.3625 42.2944 32.5614 42.6258 32.635 42.994L33.1875 45.75Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.875 18.125L40.4171 18.6385C42.0259 19.1747 42.8302 19.4429 43.2901 20.0815C43.75 20.7201 43.75 21.57 43.75 23.2632V23.3819C43.75 24.7794 43.75 25.4798 43.4136 26.0518C43.0772 26.6238 42.4646 26.9634 41.2426 27.6442L36.4375 30.3125M16.125 18.125L14.5829 18.6385C12.9741 19.1747 12.1697 19.4429 11.7099 20.0815C11.25 20.7201 11.25 21.57 11.25 23.2632V23.3819C11.25 24.7794 11.25 25.4798 11.5864 26.0518C11.9228 26.6238 12.5354 26.9634 13.7574 27.6442L18.5625 30.3125M26.1122 19.7874C26.7297 18.6775 27.0385 18.125 27.5 18.125C27.9615 18.125 28.2703 18.6775 28.8878 19.7874L29.047 20.0734C29.2225 20.3886 29.3102 20.5446 29.4467 20.6486C29.5849 20.7526 29.7555 20.7916 30.0967 20.868L30.4055 20.9395C31.6048 21.2109 32.2044 21.3457 32.3474 21.804C32.4904 22.2622 32.0809 22.7416 31.2635 23.6971L31.0522 23.9441C30.8199 24.2155 30.7029 24.3504 30.6509 24.5194C30.5989 24.6884 30.6168 24.8688 30.6509 25.2311L30.6834 25.561C30.8069 26.8366 30.8686 27.4753 30.4965 27.758C30.1227 28.0408 29.5605 27.7824 28.4376 27.2656L28.1484 27.1324C27.8283 26.9861 27.669 26.913 27.5 26.913C27.331 26.913 27.1717 26.9861 26.8516 27.1324L26.5624 27.2656C25.4395 27.784 24.8773 28.0408 24.5035 27.758C24.1298 27.4753 24.1931 26.8366 24.3166 25.561L24.3491 25.2311C24.3832 24.8688 24.4011 24.6884 24.3491 24.5194C24.2971 24.3504 24.1801 24.2155 23.9478 23.9441L23.7365 23.6971C22.9191 22.7416 22.5096 22.2639 22.6526 21.804C22.7956 21.3457 23.3952 21.2109 24.5945 20.9395L24.9033 20.868C25.2445 20.7916 25.4151 20.7542 25.5533 20.6486C25.6898 20.5446 25.7775 20.3886 25.953 20.0734L26.1122 19.7874Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M37.25 45.7499H17.75M35.625 13.9909C36.8292 14.22 37.5442 14.4735 38.16 15.234C38.9352 16.1863 38.8945 17.2149 38.8117 19.2721C38.5192 26.6708 36.8617 35.9999 27.5017 35.9999C18.1417 35.9999 16.4825 26.6724 16.1868 19.2721C16.1055 17.2149 16.0649 16.1846 16.8368 15.234C17.6103 14.2818 18.5349 14.1258 20.3858 13.8138C22.7374 13.4285 25.1171 13.2399 27.5 13.2499C28.6679 13.2521 29.7512 13.2889 30.75 13.3604"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient id="paint0_linear_0_1" x1="0" y1="0" x2="55" y2="55" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8244DC" />
            <stop offset="1" stopColor="#462476" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}
