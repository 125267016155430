import { ChevronRight } from 'lucide-react';
import { Component } from 'react';

export default class EventItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }
  setIsHovered = (isHovered) => {
    this.setState({ isHovered });
  };
  render() {
    const { img, label, category, index, onClick } = this.props;
    const { isHovered } = this.state;
    return (
      <div
        className="group relative flex min-h-fit  w-full flex-col items-start cursor-pointer justify-between gap-4 border-b-2 border-white/10 py-2 transition-colors duration-300 hover:border-highlight-2 lg:flex-row lg:items-center lg:px-6 lg:py-10"
        onMouseEnter={() => this.setIsHovered(true)}
        onMouseLeave={() => this.setIsHovered(false)}
        onClick={onClick}
      >
        <div className="left-0 top-0 hidden h-full w-full lg:absolute lg:block">
          {img ? (
            <img
              src={img}
              alt={label}
              className="h-full w-full object-cover opacity-0 transition-all duration-300 group-hover:opacity-50 group-hover:blur-sm"
              loading="lazy"
            />
          ) : (
            <div className="h-full w-full line-clamp-1 event-item-title-bg text-center text-[64px] object-cover opacity-0 flex items-center justify-center transition-all duration-300 group-hover:opacity-20 group-hover:blur-sm ">
              {label.toUpperCase()}
            </div>
          )}
        </div>
        <article className="z-10 flex w-full h-full  flex-col items-start gap-4 lg:w-auto lg:flex-row lg:items-center">
          <h2 className="text-base font-normal text-[#F6F6F6AB] transition-colors duration-300 group-hover:text-highlight-2 lg:text-[20px]">
            {category.toUpperCase()} <span className="text-highlight-2">#{index + 1}</span>
          </h2>
          <h1 className="text-4xl font-normal event-item-title  h-full  grow transition-all duration-300 group-hover:translate-x-5 group-hover:font-bold group-hover:text-highlight-2 lg:text-[56px]">
            {label}
          </h1>
        </article>
        <button className="relative z-10 text-[#F6F6F6AB] group-hover:text-white" onClick={onClick}>
          <span
            className={`transition-opacity duration-300 lg:absolute ${
              isHovered ? 'opacity-100 lg:opacity-0' : 'opacity-100'
            }`}
          >
            Visit
          </span>
          <span
            className={`hidden transition-opacity duration-300 lg:absolute lg:block ${
              isHovered ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <ChevronRight />
          </span>
        </button>
      </div>
    );
  }
}
