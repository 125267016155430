'use client';

import SourcedLogo from './nav/SourcedLogo';
import SearchIcon from './nav/SearchIcon';
import MenuButton from './nav/MenuButton';
import { AnimatePresence, motion } from 'framer-motion';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { searchedPerformeMain } from '../../redux/searched-events/action';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLink: {
        label: 'home',
        href: '/',
      },
      isMenuOpen: false,
      showSearchBar: false,

      // Search States
      events_lits: '',
      viewAllevents: false,
      currentPage: 1,
      event_limit: 8,
      isMouseInside: false,
      activeItem: -1,
      inputValueMain: '',
      search_results_main: [],
      inputValueMain_error: false,
    };
    this.NavLinks = [
      {
        href: '/',
        label: 'NFL Football',
      },
      {
        href: '/',
        label: 'NBA Basketball',
      },
      {
        href: '/',
        label: 'NHL Hockey',
      },
      {
        href: '/',
        label: 'MLB Baseball',
      },
      {
        href: '/',
        label: 'NCAA Football',
      },
    ];
  }

  toggleSearchBar = () => {
    this.setState({ showSearchBar: !this.state.showSearchBar });
  };

  onClickSportsName = (sportsName) => {
    if (sportsName) {
      this.props.history.push(`/sport-search/${sportsName}`);
    }
  };

  componentDidMount() {
    const { pathname } = this.props.location;
    const sportName = pathname.split('/sport-search/')[1]?.replace(/-/g, ' ');

    if (sportName) {
      const matchedLink = this.NavLinks.find((link) => link.label.toUpperCase() === sportName.toUpperCase());
      if (matchedLink) {
        this.setState({ currentLink: matchedLink });
      }
    }
  }

  // Search Functions
  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.performer_search_main !== this.props.performer_search_main) {
      this.setState({
        search_results_main: this.props.performer_search_main,
      });
    }
  }
  componentWillUnmount() {
    // Remove the click event listener when the component unmounts
    document.removeEventListener('click', this.handleDocumentClick);
  }
  handleInputChange = (e) => {
    const value = e.target.value;
    if (e.target) this.setState({ inputValueMain: value });

    // Filter suggestions based on user input
    if (value !== '') {
      let data = {
        performer_name: value,
      };
      this.props.searchedPerformeMain(data, 'performer');
    } else {
      this.setState({
        search_results_main: [],
      });
    }
  };

  searchEvent = () => {
    if (this.state.inputValueMain) {
      this.props.history.push(`/events-results/events/${this.state.inputValueMain}`);
    } else {
      this.setState({
        inputValueMain_error: true,
      });
    }
  };
  seachVenue = () => {
    if (this.state.inputValueMain) {
      this.props.history.push(`/events-results/venue/${this.state.inputValueMain}`);
    } else {
      this.setState({
        inputValueMain_error: true,
      });
    }
  };
  handleClearClick = () => {
    this.setState({ inputValueMain: '', search_results_main: [] });
  };
  onClickEvent = (eventId) => {
    if (eventId) {
      //this.props.history.push(`/event-details/${eventId}`)
      this.props.history.push(`/events-results/performer-tickets/${eventId.replace(/\s+/g, '-')}`);
      this.handleClearClick();
      this.setState({ isMenuOpen: false });
    }
  };
  handleDocumentClick = (event) => {
    const inputElement = event.target.outerHTML.includes('main-intro-search-bar');

    if (!inputElement) {
      // An input element was found within the clicked element

      this.handleClearClick();
    } else {
      // No input element was found within the clicked element
      console.log('No input element found within the clicked element.');
    }
  };
  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.state.inputValueMain != '') {
      this.props.history.push(`/events-results/performer-tickets/${this.state.inputValueMain.replace(/\s+/g, '-')}`);
      this.setState({ isMenuOpen: false });
    }
  };

  render() {
    const { location } = this.props;

    const { isMenuOpen, inputValueMain } = this.state;
    return (
      <nav
        className="fixed left-0 top-0 z-50 w-screen max-w-[100dvw] overflow-x-clip border-white/30 bg-main text-[12px] font-semibold text-white lg:border-b lg:pb-4"
        style={{ fontSize: '14px' }}
      >
        {/* nav container */}
        <div className="flex relative items-center justify-between px-4 lg:container-tw">
          <button
            onClick={() => {
              this.setState({ currentLink: { label: 'home', href: '/' } });
              this.setState({ isMenuOpen: false });
              this.props.history.push('/');
            }}
          >
            <SourcedLogo />
          </button>
          {/* Nav Links */}
          <div className="relative hidden-tw flex-row gap-6 lg:flex">
            {this.NavLinks.map((link) => (
              <div key={link.label} className="relative">
                <button
                  onClick={() => {
                    this.setState({ currentLink: link });
                    this.onClickSportsName(link.label.replace(/\s+/g, '-'));
                  }}
                  className={`transition-colors ${
                    this.state.currentLink.label === link.label ? 'text-white' : 'text-suppressed hover:text-white/80'
                  }`}
                >
                  {link.label}
                </button>
                <AnimatePresence>
                  {this.state.currentLink.label === link.label && (
                    <motion.div
                      initial={{ opacity: 0, width: 0 }}
                      animate={{ opacity: 1, width: '100%' }}
                      exit={{ opacity: 0, width: 0 }}
                      className="absolute bottom-0 left-0 h-[2px] w-full bg-highlight"
                    />
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
          {/* Seach and Login */}
          <div className="hidden-tw items-center gap-4 lg:flex">
            {/* <SearchIcon /> */}
            <a
              href="https://accounts.sourcedtickets.com/?register=false"
              target="_blank"
              className="group-hover-parent relative"
              style={{ textDecoration: 'none' }}
            >
              Login
              <div className="absolute left-0 top-full h-[2px] w-full bg-highlight group-hover-child" />
            </a>
            <a
              href="https://accounts.sourcedtickets.com/?register=true"
              target="_blank"
              className="w-fit rounded-md bg-highlight px-4 py-2"
            >
              Join Now
            </a>
          </div>
          <button
            className="lg:hidden-tw"
            onClick={() => this.setState({ isMenuOpen: !isMenuOpen, showSearchBar: false })}
          >
            <MenuButton isOpen={isMenuOpen} />
          </button>
          <AnimatePresence>
            {this.state.isMenuOpen && (
              <motion.nav
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -100 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                className="fixed left-0 top-[60px] z-50 h-full min-h-[100dvh] w-full border-t border-white/10 bg-main lg:hidden-tw"
              >
                {/* Search and Login */}
                <div className="flex flex-row items-center justify-between border-b border-white/10 p-4 py-6">
                  <button onClick={this.toggleSearchBar}>
                    <SearchIcon />
                  </button>
                  <div className="flex flex-row items-center gap-4">
                    <a
                      href="https://accounts.sourcedtickets.com/?register=false"
                      target="_blank"
                      onClick={() => {
                        this.setState({ isMenuOpen: false });
                      }}
                      className="group relative"
                    >
                      Login
                      <div className="absolute left-0 top-full h-[2px] w-full bg-highlight opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                    </a>
                    <a
                      href="https://accounts.sourcedtickets.com/?register=true"
                      target="_blank"
                      onClick={() => {
                        this.setState({ isMenuOpen: false });
                      }}
                      className="w-fit rounded-md bg-highlight px-4 py-2"
                    >
                      Join Now
                    </a>
                  </div>
                </div>

                {/* Search Bar */}
                <AnimatePresence>
                  {this.state.showSearchBar && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="flex relative flex-row items-center justify-between gap-4 border-b border-white/10 focus:outline-none"
                    >
                      <input
                        type="text"
                        placeholder="Search for Artist, Team, or Performer"
                        value={inputValueMain}
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                        className="w-full bg-transparent text-white placeholder:text-white/50 my-4 ml-4 focus:outline-none text-lg"
                      />
                      {inputValueMain && (
                        <i
                          className=" fa fa-times mr-4 cross-main"
                          aria-hidden="true"
                          onClick={this.handleClearClick}
                        ></i>
                      )}
                      <AnimatePresence>
                        {this.state.search_results_main.length > 0 ? (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            className="search-results-mobile shadow-lg"
                          >
                            <ul>
                              <li className="suggestion-list-items">
                                <div className="suggestion_box-main">
                                  <div className="suggestion_name-main">
                                    <h4 className="text-center"> Suggested Results </h4>
                                  </div>
                                </div>
                              </li>
                              {this.state.search_results_main.length > 0 &&
                                this.state.search_results_main.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    onClick={() => this.onClickEvent(suggestion.name)}
                                    className="suggestion-list-items-main"
                                  >
                                    <div className="suggestion_box-main">
                                      <div className="suggestion_name-main">
                                        <h3> {suggestion.name} </h3>
                                        {/* <h6 className="search-city-name-main"> {moment(suggestion.date.date).format(" ddd MM/D")} • {suggestion.city.text.name},{suggestion.stateProvince.text.name}  </h6> */}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </motion.div>
                        ) : (
                          ''
                        )}
                      </AnimatePresence>
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* Nav Links */}
                <div className="flex w-full flex-col items-start justify-start gap-4 py-6">
                  {this.NavLinks.map((link) => (
                    <div key={link.label} className="relative">
                      <button
                        onClick={() => {
                          this.setState({ currentLink: link });
                          this.setState({ isMenuOpen: false });
                          this.onClickSportsName(link.label.replace(/\s+/g, '-'));
                        }}
                        className={`${
                          this.state.currentLink.label === link.label ? 'pl-10 text-white' : 'pl-6 text-[#F1DEDE99]'
                        } z-20 text-[32px] transition-all duration-300`}
                      >
                        {link.label}
                      </button>

                      <AnimatePresence>
                        {this.state.currentLink.label === link.label && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            aria-hidden-tw
                            className="absolute left-0 top-0 z-10 translate-y-[-18%] whitespace-nowrap text-[64px] leading-none text-white/5"
                          >
                            {link.label}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ))}
                </div>
              </motion.nav>
            )}
          </AnimatePresence>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  user_info: state.user_info,
  user_added: state.user_added,
  performer_search_main: state.performer_search_main.performer_search_main,
});

export default withRouter(connect(mapStateToProps, { searchedPerformeMain })(NavBar));
