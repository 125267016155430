import { motion } from 'framer-motion';
import EventItem from '../EventItem';
import Basketball from '../../../../assets/images/categoryImages/Sports/basketball-min.webp';
import Football from '../../../../assets/images/categoryImages/Sports/football.webp';
import Hockey from '../../../../assets/images/categoryImages/Sports/hockey.webp';
import Baseball from '../../../../assets/images/categoryImages/Sports/baseball.webp';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class SportsView extends Component {
  onClickSportsName = (name) => {
    this.props.history.push(`/sport-search/${name.replace(/\s+/g, '-')}`);
  };
  render() {
    const sportItems = [
      {
        label: 'NFL Football',
        img: Football,
      },
      {
        label: 'NBA Basketball',
        img: Basketball,
      },
      {
        label: 'NHL Hockey',
        img: Hockey,
      },
      {
        label: 'MLB Baseball',
        img: Baseball,
      },
      {
        label: 'NCAA Football',
        img: Football,
      },
    ];
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="flex w-full  flex-col lg:pb-24"
      >
        {sportItems.map((item, i) => (
          <EventItem
            key={item.label}
            index={i}
            category="sports"
            {...item}
            onClick={() => this.onClickSportsName(item.label)}
          />
        ))}
      </motion.div>
    );
  }
}

export default withRouter(connect(null, {})(SportsView));
