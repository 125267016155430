// src/redux/reducer.js
const initialState = {
  search_query: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...state,
        search_query: action.payload,
      };
    case 'GET_SEARCH_QUERY':
      return {
        ...state,
        search_query: state.search_query,
      };
    default:
      return state;
  }
};
