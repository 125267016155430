import React, { Component } from 'react';

import AboutUsSection from '../components/landing/AboutUs/AboutUsSection';
import EventSection from '../components/landing/Event/EventSection';
import FeaturesSection from '../components/landing/Features/FeaturesSection';
import HeroSection from '../components/landing/Hero';
import WhyUsSection from '../components/landing/WhyUs/WhyUsSection';
import LocalEvents from '../components/local-events/localEvents';
import SellNow from '../components/sell-now/sellNow';
import TopSection from '../components/top-section/topSection';
import UnmatchedFeature from '../components/unmatched-feature/unmatchedFeature';
import MainIntro from '../components/main-intro/main-intro';
class Home extends Component {
  render() {
    return (
      // <>
      //   <title>Sourced Tickets</title>

      //   <div>
      //     <MainIntro />
      //   </div>

      //   <div>
      //     <LocalEvents />
      //   </div>
      //   <div>
      //     <UnmatchedFeature />
      //   </div>
      //   <div>
      //     <TopSection />
      //   </div>
      //   <div>
      //     <SellNow />
      //   </div>
      // </>
      <>
        <title>Sourced Tickets</title>

        <section id="home-new" className=" max-w-[100dvw] flex flex-col bg-white">
          <HeroSection />
          <WhyUsSection />
          <div className="features-clip -mb-[0.7px] h-[100px] bg-main" aria-hidden />
          {/* <EventSection /> */}
          <div>
            <TopSection />
          </div>
          <FeaturesSection />
          <AboutUsSection />
        </section>
        {/* <div>
          <LocalEvents />
        </div> */}
      </>
    );
  }
}

export default Home;
